<template>
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="process_data"
    />
  </template>

  <script>
  import { defineComponent, defineProps } from 'vue';
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  export default defineComponent({
    components: { Bar },

    props: ['data_input'],

    data() {
      return {
        chartData: {
          labels: [ 'January', 'February', 'March'],
          datasets: [
                {
                    label: 'Data One',
                    backgroundColor: '#0FBAB2',
                    data: [40, 20, 12]
                },
                {
                    label: 'Data Two',
                    backgroundColor: '#1791aa',
                    data: [40, 20, 12]
                },
                {
                    label: 'Data Three',
                    backgroundColor: '#3b6b8f',
                    data: [4, 20, 12]
                }
            ]
        },
        chartOptions: {
          responsive: true,
          scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true
                }
            },
        onResize : true
        }
      }
    },

    computed: {
        process_data(){
            //console.log(this.data_input);

            let data_label = [];
            let triage_1 = [];
            let triage_2 = [];
            let triage_3 = [];
            let triage_4 = [];

            for(let i=0; i<this.data_input.length; i++){
                data_label.push(this.data_input[i].date);
                triage_1.push(this.data_input[i].triage_1);
                triage_2.push(this.data_input[i].triage_2);
                triage_3.push(this.data_input[i].triage_3);
                triage_4.push(this.data_input[i].triage_4);
            }

            let output_data = {
                labels: data_label,
                datasets : [
                    {
                        label: 'Emergency Care (4)',
                        backgroundColor: '#ff2929',
                        data: triage_4
                    },
                    {
                        label: 'Urgent (3)',
                        backgroundColor: '#FF802B',
                        data: triage_3
                    },
                    {
                        label: 'See a Doctor (2)',
                        backgroundColor: '#F7B924',
                        data: triage_2
                    },
                    {
                        label: 'Self Care (1)',
                        backgroundColor: '#4DC99A',
                        data: triage_1
                    },
                ]
            }

            return output_data

        }
    },





  });
  </script>
