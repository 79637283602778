import { defineStore } from 'pinia';
import axios from 'axios';

const BACKEND_URL = 'https://langserve-copilot-dp7nm56cca-as.a.run.app';
let refreshAttemptInProgress = false;
let lastRefreshAttempt = 0;
const REFRESH_COOLDOWN = 5000; // 5 seconds cooldown

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: null,
    refreshToken: null,
    tokenType: null,
    userRole: null,  // Store the role
    userDetails: {}
  }),
  actions: {

    async login(username, password) {
      try {
        // Create a FormData object
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        // Send the FormData object in the POST request
        const response = await axios.post(`${BACKEND_URL}/nora_user/token`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { access_token, refresh_token, token_type } = response.data;
        this.accessToken = access_token;
        this.refreshToken = refresh_token;
        this.tokenType = token_type;

        // Store tokens in localStorage
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('tokenType', token_type);

        // Fetch user details
        const userDetailsResponse = await axios.get(`${BACKEND_URL}/nora_user/details`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        });

        const userRole = userDetailsResponse.data.user_info.Role;
        if (userRole !== 'admin' && userRole !== 'clinic_admin') {
          throw new Error('Unauthorized role');
        }

        // Optionally redirect or show a success message
        return true;

      } catch (error) {
        console.error('Login error:', error);
        const errorMessage = this.handleError(error);
        return errorMessage;
      }
    },

    async fetchUserRoles() {
      try {
        const response = await axios.get(`${BACKEND_URL}/nora_user/details`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        });

        const { user_info } = response.data;
        this.userRole = user_info.Role;  // Store the role in the state

      } catch (error) {
        console.error('Error fetching user details:', error);
        this.logout();
      }
    },

    async fetchUserDetails() {
      try {
        const response = await axios.get(`${BACKEND_URL}/nora_user/details`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        });

        this.userDetails = response.data.user_info;  // Store the role in the state
        return response.data.user_info;

      } catch (error) {
        console.error('Error fetching user details:', error);
        this.logout();
      }
    },

    async refreshAccessToken() {
      // Return early if no refresh token
      if (!this.refreshToken) {
        console.error('No refresh token available');
        this.logout();
        return;
      }
    
      // Prevent concurrent refresh attempts
      if (refreshAttemptInProgress) {
        return;
      }
    
      // Check if enough time has passed since last attempt
      const now = Date.now();
      if (now - lastRefreshAttempt < REFRESH_COOLDOWN) {
        return;
      }
    
      try {
        refreshAttemptInProgress = true;
        lastRefreshAttempt = now;
    
        const response = await axios.post(
          `${BACKEND_URL}/nora_user/token/refresh?refresh_token=${this.refreshToken}`
        );
    
        const { access_token, refresh_token, token_type } = response.data;
        this.accessToken = access_token;
        this.refreshToken = refresh_token;
        this.tokenType = token_type;
    
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('tokenType', token_type);
      } catch (error) {
        console.error('Error refreshing token:', error);
        this.logout();
      } finally {
        refreshAttemptInProgress = false;
      }
    },

    logout() {
      this.accessToken = null;
      this.refreshToken = null;
      this.tokenType = null;
      this.userRole = null;  // Clear the user role
      this.userDetails = {};

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenType');
    },

    loadTokensFromStorage() {
      this.accessToken = localStorage.getItem('accessToken');
      this.refreshToken = localStorage.getItem('refreshToken');
      this.tokenType = localStorage.getItem('tokenType');
    },

    handleError(error) {
      let errorMessage = 'An unexpected error occurred. Please try again later.';
    
      if (error.message === 'Unauthorized role') {
        // Specific error for unauthorized roles
        errorMessage = 'Access denied. You do not have the required permissions to access this app.';
      } else if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = 'Invalid username or password.';
            break;
          case 401:
            errorMessage = 'Unauthorized access.';
            break;
          case 500:
            errorMessage = 'Server error.';
            break;
          default:
            errorMessage = 'Something went wrong.';
        }
      } else if (error.request) {
        errorMessage = 'No response from server.';
      }
    
      return errorMessage;
    },

    async get_url(){
      if (this.userRole == 'admin'){
        return `${BACKEND_URL}/nora_superadmin`;
      }
      else if (this.userRole == 'clinic_admin'){
        return `${BACKEND_URL}/nora_clinicadmin`;
      }
    },
    

  },
});
