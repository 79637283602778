<template>
    <div v-if="isVisible" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
                <p>Flow Visualization</p>
                <div class="header-actions">
                    <button class="action-button" @click="transformToFlowElements">
                        <span class="desktop-view"><i class="fas fa-sync-alt"></i> Reset Nodes</span>
                        <span class="mobile-view"><i class="fas fa-sync-alt"></i></span>
                    </button>
                    <button class="action-button" @click="centerFlow">
                        <span class="desktop-view"><i class="far fa-eye"></i> Reset View</span>
                        <span class="mobile-view"><i class="far fa-eye"></i></span>
                    </button>
                  <button class="action-button" @click="closeModal">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>

          <div class="flow-wrapper dotted-background">
            <VueFlow 
                v-model="elements" 
                :default-viewport="viewport"
                :fit-view-on-init="true"
                :min-zoom="0.2"
                :max-zoom="4"
                @nodeClick="onNodeClick"
            >
            
              <template #node-question="{ data }">
                <div class="question-node" :class="{ 'start-node': data.isStart }">
                    <div class="question-text">{{ data.text }}</div>
                </div>
              </template>
              <template #node-outcome="{ data }">
                <div class="outcome-node">
                  <div>Outcome Level: {{ data.level }}</div>
                </div>
              </template>
            </VueFlow>
          </div>
          
        </div>
      </div>
  </template>
  
  <script>
  import { VueFlow, useVueFlow } from '@vue-flow/core'
  import '@vue-flow/core/dist/style.css'
  
  export default {
    name: 'QuestionFlowChart',
    components: { VueFlow },
    props: {
      flowData: {
        type: Object,
        required: true
      },
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    setup() {
        const { 
        onNodesChange, 
        onEdgesChange, 
        onConnect,
        fitView,
        setViewport 
        } = useVueFlow()
        
        return { 
        onNodesChange, 
        onEdgesChange, 
        onConnect,
        fitView,
        setViewport
        }
    },
    data() {
      return {
        elements: [],
        viewport: {
            x: 0,
            y: 0,
            zoom: 0.5
        }
      }
    },
    methods: {
    closeModal() {
        this.$emit('close')
    },
    centerFlow() {
      if (typeof window !== 'undefined') {
        // Add small delay to ensure Vue Flow is ready
        setTimeout(() => {
          this.setViewport({
            x: window.innerWidth / 4,
            y: window.innerHeight / 4,
            zoom: 0.5
          })
          
          this.fitView({
            padding: 0.2,
            duration: 200
          })
        }, 100)
      }
    },
    transformToFlowElements() {
    const nodes = []
    const edges = []
    let yOffset = 0
    
    if(this.flowData.questions){
        Object.entries(this.flowData.questions).forEach(([id, data]) => {
        // Create question node
        nodes.push({
            id,
            type: 'question',
            position: { x: 0, y: yOffset },
            data: { 
            text: data.text,
            isStart: this.flowData.settings?.start === id // Compare with settings.start
            },
        })

        // Create edges for choices
        Object.entries(data.choices).forEach(([choice, result]) => {
            if (result.resulttype === 'flow') {
            edges.push({
                id: `${id}-${result.resultdetail}`,
                source: id,
                target: result.resultdetail,
                label: choice,
                type: 'smoothstep',
            })
            } else {
            // Create outcome node
            const outcomeId = `outcome-${id}-${choice}`
            nodes.push({
                id: outcomeId,
                type: 'outcome',
                position: { x: choice === 'yes' ? 400 : -500, y: yOffset },
                data: { level: result.resultdetail },
            })
            edges.push({
                id: `${id}-${outcomeId}`,
                source: id,
                target: outcomeId,
                label: choice,
                type: 'smoothstep',
            })
            }
        })

        yOffset += 200
        })
    }

    this.elements = [...nodes, ...edges]
    }
    },
    mounted() {
        this.centerFlow()
        window.addEventListener('resize', this.centerFlow)
    },
    
    beforeUnmount() {
        window.removeEventListener('resize', this.centerFlow)
    },

    watch: {
      flowData: {
        handler() {
          this.transformToFlowElements()
        },
        deep: true
      },
      isVisible: {
            handler() {
                if (this.isVisible) {
                    this.centerFlow()
                }
            }
        }
    }
  }
  </script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  width: 70%;
  height: 90%;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 95%;
    height: 95%;
    padding: 15px;
    margin: 10px;
  }
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
</style>
  
<style scoped>
.flow-wrapper {
width: 100%;
height: 80vh; /* Adjust height to fit viewport better */
display: flex;
justify-content: center;
align-items: center;
background-color: #f9fafb;
background-image: radial-gradient(#e5e7eb 1px, transparent 1px);
background-size: 20px 20px;
border-radius: 12px;
}

.question-node {
padding: 15px;
border-radius: 8px;
background: white;
border: 2px solid #2563eb;
width: 250px;
}

.start-node {
border: 3px solid #8139af; /* Purple border for start node */
box-shadow: 0 0 10px rgba(129, 57, 175, 0.3); /* Subtle glow effect */
}

.start-node::before {
content: "Start";
position: absolute;
top: -25px;
left: 50%;
transform: translateX(-50%);
background: #8139af;
color: white;
padding: 2px 8px;
border-radius: 4px;
font-size: 12px;
}

.outcome-node {
padding: 10px;
border-radius: 8px;
background: #10b981;
color: white;
text-align: center;
}

.question-text {
font-size: 14px;
margin-bottom: 10px;
}

.choices {
display: flex;
justify-content: space-between;
gap: 10px;
}

.choice {
padding: 4px 8px;
border-radius: 4px;
font-size: 12px;
}

.choice.yes {
background: #dcfce7;
color: #166534;
}

.choice.no {
background: #fee2e2;
color: #991b1b;
}
</style>
  
<style scoped>
.header-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.action-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  color: #666;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: #f0f0f0;
  color: #333;
}
</style>

<style scoped>
/*show desktop only on normal width*/
.desktop-view{
    display: block;
}
.mobile-view{
    display: none;
}

@media (max-width: 785px) {
    /*show mobile only*/
    .desktop-view{
        display: none;
    }
    .mobile-view{
        display: flex;
    }
}
</style>