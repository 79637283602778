<template>

  <transition name="loadfade">
    <Loading v-if="isLoading"/>
  </transition>

  <div class="container-fluid vh-100 d-flex flex-column justify-content-center align-items-center">
    <!-- Title -->
    <div class="text-center mb-4">
      <img src="@/assets/qmedNora1.png" class="logo-img" >
      <h4><b>Symptom Question Builder</b></h4>
    </div>

    <!-- Card -->
    <div class="card shadow card-width">
      <!-- ====== LOGIN ====== -->
      <div class="card-body">
        <div class="text-center my-3">Login</div>
        <div class="m-3">
          <input type="username" id="username" class="form-control input-form" v-model="username" placeholder="Username">
        </div>
        <div class="m-3">
          <div class="input-group v">
            <input type="password" id="password" class="form-control input-form" v-model="password" placeholder="Password">
            <button class="btn btn-outline-secondary" :class="{'active' : showPassword}" type="button" @click="togglePasswordVisibility">
              <i class="fas fa-eye" :class="{ 'fa-eye-slash': !showPassword }"></i>
            </button>
          </div>
        </div>

        <div class="m-3">
          <button type="submit" class="btn green-btn" style="width: 100%;" @click="login" @keyup.enter="login">Login</button>
        </div>

        <div class="text-center mt-3" style="font-size: 12px; color:red;" v-if="error_text">
          <a>{{ error_text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import Loading from "@/components/Loading.vue";

// Initialize Pinia store and router
const authStore = useAuthStore();
const router = useRouter();

// Component state
const showPassword = ref(false);
const error_text = ref('');
const username = ref('');
const password = ref('');
const isLoading = ref(false);

// Methods
function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
  const passwordInput = document.getElementById('password');
  if (passwordInput) {
    passwordInput.type = showPassword.value ? 'text' : 'password';
  }
}

async function login() {
    isLoading.value = true;
    const login_res = await authStore.login(username.value, password.value);
    isLoading.value = false;
    
    // Wait for user role to be fetched
    await authStore.fetchUserRoles();

    if (login_res === true) {
            router.push('/');
    } else {
        error_text.value = login_res;
    }
}
</script>


<style scoped>

div{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  }

.container-fluid{
  font-family: 'Figtree', sans-serif;
}

.input-form {
  font-size: 12px;
}
.card-width {
  width: 30%;
}
.green-btn {
  background-color: var(--primary-color) !important;
  color: white !important;
  padding: 8px !important;
}
.green-btn:hover {
  background-color: var(--primary-hover) !important;
}
@media (max-width: 785px) {
  .card-width {
    width: 100%;
  }
}

.loadfade-enter-active,
.loadfade-leave-active {
  transition: opacity 0.3s
}

.loadfade-enter-from,
.loadfade-leave-to {
  opacity: 0
}

.logo-img{
width:50%
}
</style>
