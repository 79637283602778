<template>
    <transition-group name="fading">
        <div class="overlay" v-if="isActive">
            <div class="overlay-content" v-if="loaded">
                <div class="container">
                  <div class="card round-card">
      
                    <div class="card-body">
                        <div class="fs-3">Symptom Flow</div>
    
                        <div class="overlay-close"><!-- EXIT BUTTON -->
                          <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="close_modal"></i></div>
                        </div>
      
                        <div class="card-body">
                          <label class="row mx-1 my-1">Flow Question after triage for this Symptom</label>
                            <select class="form-select" v-model="var_data.triageflow">
                                <!-- <option value="">Choose the Result Detail for Flow</option> -->
                                <option v-for="(val, key) in select_list" :key="key" :value="key">{{ val.flowname }}</option>
                            </select>

                        </div>
      
                        <div class="d-flex justify-content-center">
                          <button class="btn red-btn mx-1 w-25" @click="close_modal()"><i class="fa fa-ban"></i> Cancel</button>
                          <button class="btn green-btn mx-1 w-25" @click="submit()"><i class="fa fa-floppy-o"></i> Save</button>
                        </div>                        
                    </div>
      
                  </div>
                </div>
            </div>
    
              <Loading v-else/>
        </div>
    </transition-group>
</template>

<script>

import axios from "axios";
import Loading from "@/components/Loading.vue";
import URL from "@/pages/URL.json";
const API_URL = URL.api_url;
//PROD https://triage-symptom-db-default-rtdb.asia-southeast1.firebasedatabase.app/
//DEV https://nora-db-default-rtdb.asia-southeast1.firebasedatabase.app/

export default{

components: {
    Loading,
},

props: {
    // Define 'message' as a prop to receive data from parent
    inputID: {
        type: String,
        required: true,
    },
    isActive: {
        type: Boolean,
    }
},

mounted() {
    this.get_current_data();
    this.get_flow_selection()
},

watch: {
    isActive(newVal) {
      if (newVal) {
        this.get_current_data() // Call the function when isActive becomes true
      }
    },
},

data() {return {   
    var_data: [],
    loaded: false,
    select_list: {},
    }
},
methods: {

async get_current_data(){
    this.loaded = false;
    let api = `${API_URL}/symptoms/${this.inputID}.json`;

    const response = await axios.get(
        api,{
        headers: {
            "Content-Type": "application/json",
            },
        }
    );
    if(response.status = 'success'){
        this.var_data = response.data;
        this.loaded = true;
        console.log("DATA")
        console.log(this.var_data);
    }
    else{
        console.error(response);
    }
},

close_modal(){
    this.$emit('close-modal');
},

async submit(){
    this.loaded = false;
    let api = `${API_URL}/symptoms/${this.inputID}.json`;
    console.log(this.var_data);

    try {
          // Make a PATCH instead of POST because POST generate unique ID while PATCH replace request to update synonyms
          console.warn("Updating Question Details..");
          await axios.patch(api,this.var_data);
          this.loaded = true;
          this.get_current_data();

          this.$emit('edit-submit');

          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }
},

async get_flow_selection(){
    let api = `${API_URL}/triageflow.json`;
    console.log("FLOW SELECTION");
    
    const response = await axios.get(
        api,{
        headers: {"Content-Type": "application/json"},
        }
    );

    if(response.status = 'success'){
        this.select_list = response.data;
    }
    else{
        console.error(response);
    }

    console.log(this.select_list);
}


}

}


</script>

<style>
.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}
</style>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:100% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 62, 65, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card.hidden{
    border: none;
    background-color: rgba(255, 255, 255, 0)
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #dc362e !important;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.disabled-input{
    background-color: #f9fafe !important;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 14px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.absolute-right {
position: absolute;
top: 10px;
right: 10px;
}

.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
}

.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.no-border{
    border: none;
}

.bold-txt{
    font-weight: 550;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
font-size: 2rem;
}
  
.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
font-size: 1.5rem;
cursor: pointer;
white-space: nowrap;
}

.fav{
    background-color:#f9effa;
    color: black;
}


.fav:hover{
    background-color:#8139af;
    color: white;
}

.fav.active{
    background-color:#8139af;
    color: white;
}

.fav.active:hover{
    background-color:#f9effa;
    color: black;
}
</style>