<script>
import {defineComponent} from "vue";
import {Icon} from "@iconify/vue";

export default defineComponent({
  components: {Icon},
  props: {
    isButton: {
      required: false,
    },
    icon: {
      required: true
    }
  }
})
</script>
<template>
  <Icon :icon="icon" :style="{'cursor': isButton ? 'pointer' : 'default'}" />
</template>
