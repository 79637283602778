<template>
<div v-if="isVisible" class="modal-overlay">
    <div class="modal-content">
        <div class="modal-header">
            <div>Flow Visualization</div>
            <div class="header-actions">
                <button class="action-button" @click="transformToFlowElements">
                    <span class="desktop-view"><i class="fas fa-sync-alt"></i> Reset Nodes</span>
                    <span class="mobile-view"><i class="fas fa-sync-alt"></i></span>
                </button>
                <button class="action-button" @click="centerFlow">
                    <span class="desktop-view"><i class="far fa-eye"></i> Reset View</span>
                    <span class="mobile-view"><i class="far fa-eye"></i></span>
                </button>
              <button class="action-button" @click="closeModal">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

        <div class="flow-wrapper dotted-background">
            <VueFlow 
            v-model="elements" 
            :default-viewport="viewport"
            :fit-view-on-init="true"
            :min-zoom="0.2"
            :max-zoom="4"
            >
            <template #node-question="{ data }">
                    <div class="question-node" :class="{ 'start-node': data.isStart }">
                    <div class="question-text">{{ data.text }}</div>
                    <div v-if="data.imageURL" class="question-image">
                        <img :src="data.imageURL" alt="Question Image" />
                    </div>
                    <!-- <div class="choices-container" style="bottom: -1000px;">
                        <div v-for="choice in data.choices" :key="choice.id" 
                            class="choice-item" :class="{ 'connected': choice.questionflow }">
                        {{ choice.text }}
                        </div>
                    </div> -->
                    </div>
              </template>

              <template #edge-default="{ sourceNode, targetNode, label }">
                <path 
                  class="vue-flow__edge-path" 
                  :d="getBezierPath({
                    sourceX: sourceNode.position.x,
                    sourceY: sourceNode.position.y,
                    targetX: targetNode.position.x,
                    targetY: targetNode.position.y,
                  })"
                />
                <EdgeLabelRenderer>
                  <div
                    class="choices-container nodrag nopan"
                    :style="{
                      transform: `translate(-50%, -50%) translate(
                        ${(sourceNode.position.x + targetNode.position.x) / 2}px,
                        ${(sourceNode.position.y + targetNode.position.y) / 2}px
                      )`
                    }"
                  >
                  <span style="font-size:10px">Choices:</span>
                    <div v-for="(choice, index) in label.split('|||')" 
                         :key="index" 
                         class="choice-card">
                      {{ choice }}
                    </div>
                  </div>
                </EdgeLabelRenderer>
              </template>
              
            </VueFlow>
        </div>
    </div>
</div>
</template>

<script>
import { VueFlow, EdgeLabelRenderer, getBezierPath, useVueFlow } from '@vue-flow/core'

export default {
name: 'QuestionFlowVisual',
components: {
    VueFlow,
    EdgeLabelRenderer
  },
props: {
    flowData: {
    type: Object,
    required: true
    },
    isVisible: {
    type: Boolean,
    default: false
    }
},

setup() {
    const { onNodesChange, onEdgesChange, onConnect, fitView, setViewport } = useVueFlow()
    return { onNodesChange, onEdgesChange, onConnect, fitView, setViewport, getBezierPath,}
},

data() {
    return {
    elements: [],
    viewport: {
        x: 0,
        y: 0,
        zoom: 0.5
    }
    }
},

methods: {
    transformToFlowElements() {
      const nodes = []
      const edges = []
      let yOffset = 0
      
      if(this.flowData.questions) {
        Object.entries(this.flowData.questions).forEach(([id, data]) => {
          nodes.push({
            id,
            type: 'question',
            position: { x: 0, y: yOffset },
            data: { 
              text: data.text,
              imageURL: data.images?.imageURL,
              type: data.type,
              isStart: this.flowData.settings?.start === id
            },
          })

          const choicesByTarget = {}
          if (data.choices) {
            Object.values(data.choices).forEach(choice => {
              if (choice.questionflow) {
                if (!choicesByTarget[choice.questionflow]) {
                  choicesByTarget[choice.questionflow] = []
                }
                choicesByTarget[choice.questionflow].push(choice.text)
              }
            })
          }

          Object.entries(choicesByTarget).forEach(([targetId, choiceTexts]) => {
            edges.push({
              id: `${id}-${targetId}`,
              source: id,
              target: targetId,
              label: choiceTexts.join('|||'),
              type: 'default', // Changed to default type to use our custom template
              style: { stroke: '#2563eb' }
            })
          })

          yOffset += 400
        })
      }

      this.elements = [...nodes, ...edges]
    },

    centerFlow() {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
            this.setViewport({
                x: window.innerWidth / 4,
                y: window.innerHeight / 4,
                zoom: 0.5
            })
            
            this.fitView({
                padding: 0.2,
                duration: 200
            })
            }, 100)
        }
    },

    closeModal() {
    this.$emit('close')
    }
},

mounted() {
    this.transformToFlowElements()
    this.centerFlow()
    window.addEventListener('resize', this.centerFlow)
},

beforeUnmount() {
    window.removeEventListener('resize', this.centerFlow)
},

watch: {
    flowData: {
    handler() {
        this.transformToFlowElements()
    },
    deep: true
    },
    isVisible: {
    handler(newValue) {
        if (newValue) {
        this.$nextTick(() => {
            this.centerFlow()
        })
        }
    }
    }
}
}
</script>

<style scoped>
/* Previous styles plus: */
.question-image {
margin-top: 10px;
width: 100%;
height: 100px;
overflow: hidden;
border-radius: 4px;
}

.question-image img {
width: 100%;
height: 100%;
object-fit: cover;
}

.question-node {
width: 300px; /* Wider to accommodate images */
}
</style>
  
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  width: 70%;
  height: 90%;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 95%;
    height: 95%;
    padding: 15px;
    margin: 10px;
  }
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
</style>
  
<style scoped>
.flow-wrapper {
width: 100%;
height: 80vh; /* Adjust height to fit viewport better */
display: flex;
justify-content: center;
align-items: center;
background-color: #f9fafb;
background-image: radial-gradient(#e5e7eb 1px, transparent 1px);
background-size: 20px 20px;
border-radius: 12px;
}

.question-node {
padding: 15px;
border-radius: 8px;
background: white;
border: 2px solid #2563eb;
width: 250px;
}

.start-node {
border: 3px solid #8139af; /* Purple border for start node */
box-shadow: 0 0 10px rgba(129, 57, 175, 0.3); /* Subtle glow effect */
}

.start-node::before {
content: "Start";
position: absolute;
top: -25px;
left: 50%;
transform: translateX(-50%);
background: #8139af;
color: white;
padding: 2px 8px;
border-radius: 4px;
font-size: 12px;
}

.outcome-node {
padding: 10px;
border-radius: 8px;
background: #10b981;
color: white;
text-align: center;
}

.question-text {
font-size: 14px;
margin-bottom: 10px;
}

.choices {
display: flex;
justify-content: space-between;
gap: 10px;
}

.choice {
padding: 4px 8px;
border-radius: 4px;
font-size: 12px;
}

.choice.yes {
background: #dcfce7;
color: #166534;
}

.choice.no {
background: #fee2e2;
color: #991b1b;
}
</style>
  
<style scoped>
.header-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.action-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  color: #666;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: #f0f0f0;
  color: #333;
}
</style>

<style scoped>
.choices-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.choice-item {
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  background: #f3f4f6;
  border: 1px solid #e5e7eb;
}

.choice-item.connected {
  background: #dbeafe;
  border-color: #2563eb;
  color: #1e40af;
}

.question-node {
  width: 350px;
  padding: 15px;
}
</style>

<style scoped>
.choices-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  min-width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: all;
  z-index: 1000;
}

.choice-card {
  padding: 6px 12px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 12px;
  color: #374151;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  white-space: normal;
  text-align: left;
}

.vue-flow__edge-path {
  stroke: #2563eb;
  stroke-width: 2;
}
</style>

<style scoped>
/*show desktop only on normal width*/
.desktop-view{
    display: block;
}
.mobile-view{
    display: none;
}

@media (max-width: 785px) {
    /*show mobile only*/
    .desktop-view{
        display: none;
    }
    .mobile-view{
        display: flex;
    }
}
</style>