// src/services/dashboardService.js
import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

export const fetchDashboardData = async (location, period) => {
  const authStore = useAuthStore();

  try {
    const response = await axios.get('https://langserve-copilot-dp7nm56cca-as.a.run.app/nora_admin/v2/dashboard', {
      params: {
        location,
        period,
      },
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Token might be expired, attempt to refresh
      await authStore.refreshAccessToken();
    }
    throw error;
  }
};
