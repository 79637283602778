<template>
    <head>
        <link href="https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,500;0,600;0,700;0,900;1,400&display=swap" rel="stylesheet">
    </head>
    <div class="nav-group">
        <nav class="navbar platform-size-nav" style="width:80%; padding: 15px 0px!important; margin: auto; border-radius: 10px; background-color: #f8f9fad9 !important;">
          <img src="" class="logo-img">

          <div class="d-flex">
            <div class="form-check form-switch my-2 mx-1">
              <input class="form-check-input" type="checkbox" v-model='period_switch' @click="change_period()">
            </div>

            <div v-if="period=='1month'" class="btn btn-outline-primary pe-none mx-1 my-1" style="color: #0FBAB2; border-color:#0FBAB2;">
              <a>1 Month</a>
            </div>
            <div v-if="period=='7days'" class="btn pe-none mx-1 my-1 text-white" style="background-color: #0FBAB2">
              <a>1 Week</a>
            </div>

            <div class="btn pe-none mx-1 my-1" style="background-color:white; border: 2px solid #6C756B;"><Icon icon="uil:calender" style="font-size:20px;"/> <a>{{start_date}} - {{end_date}}</a></div>
            <div class="btn pe-none mx-1 my-1" style="background-color:white; border: 2px solid #6C756B;"><Icon icon="circum:medical-case" style="font-size:20px;"/> <a>Total Cases : {{total_count}}</a></div>
          </div>

        </nav>
      </div>

          <!-- DESKTOP VIEW STATS -->
          <div class="container platform-size desktop-view">
            <div class="row col-12 sec1">
              <div class="card col-6 main-card" style="padding:5px;border-radius:15px;">
                <div class="card-body">
                  <h5 class="card-title card-age-title">Gender</h5>
                  <div class="row p-2" style="width: 100%">

                      <div class="col-lg-12" v-for="(gender_val, gender_key) in gender_count" style="padding: 0;">
                        <div class="card gender-group" v-if="gender_key == 'male' || gender_key == 'female'">
                          <div class="card-body age-group">
                            <h5 class="card-title gender-title">
                              <a v-if="gender_key == 'male'" class="gender-type">Male
                                <Icon icon="ion:male" style="margin-left: 8px" />
                              </a>
                              <a v-else-if="gender_key == 'female'" class="gender-type">Female
                                <Icon icon="ion:female" style="margin-left: 8px"/>
                              </a>
                            </h5>
                            <h6 class="card-subtitle" style="width: 32%;">
                              <a style="color: rgb(15, 186, 178); padding: 4px; font-size: 20px; font-weight: 700;">
                                {{ gender_val }}
                                <span>Counts</span>
                              </a>
                            </h6>
                          </div>
                        </div>


                    </div>

                  </div>
                </div>
              </div>

              <div class="card col-6" style="padding:5px;border-radius:15px;">
                <div class="card-body age">
                  <h5 class="card-title card-age-title">Age Group</h5>
                  <div class="row p-2 col-12">

                      <div class="col-ms col-lg-6 p-1" v-for="(age_val, age_key) in age_group_count">
                        <div class="card">
                          <div class="card-body age-group">
                            <h5 class="age-title card-title">{{ age_key }} </h5>
                            <h6 class="card-subtitle">
                              <a style="padding:5px 8px; border-radius:5px; color: white;">
                                Count : {{ age_val }}
                              </a>
                            </h6>
                          </div>
                        </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- MOBILE VIEW STATS -->
          <div class="container platform-size mobile-view">
              <div class="card" style="padding:5px;border-radius:15px;">
                <div class="card-body">
                  <h5 class="card-title card-age-title text-center">Gender</h5>
                  <div class="row p-2 m-auto" style="width: 100%">
                      <div class="col-lg-12" v-for="(gender_val, gender_key) in gender_count" style="padding: 0;">
                        <div class="card gender-group" v-if="gender_key == 'male' || gender_key == 'female'">
                          <div class="card-body age-group">
                            <h5 class="card-title gender-title">
                              <a v-if="gender_key == 'male'" class="gender-type">Male
                                <Icon icon="ion:male" style="margin-left: 8px" />
                              </a>
                              <a v-else-if="gender_key == 'female'" class="gender-type">Female
                                <Icon icon="ion:female" style="margin-left: 8px"/>
                              </a>
                            </h5>
                            <h6 class="card-subtitle" style="width: 32%;">
                              <a style="color: rgb(15, 186, 178); padding: 4px; font-size: 20px; font-weight: 700;">
                                {{ gender_val }}
                                <span>Counts</span>
                              </a>
                            </h6>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="container platform-size mobile-view">
            <div class="card" style="padding:5px;border-radius:15px;">
              <div class="card-body age">
                <h5 class="card-title card-age-title text-center">Age Group</h5>
                <div class="row p-2 col-12 m-auto">

                    <div class="col-ms col-lg-6 p-1" v-for="(age_val, age_key) in age_group_count">
                      <div class="card">
                        <div class="card-body age-group">
                          <h5 class="age-title card-title">{{ age_key }} </h5>
                          <h6 class="card-subtitle">
                            <a style="padding:5px 8px; border-radius:5px; color: white;">
                              Count : {{ age_val }}
                            </a>
                          </h6>
                        </div>
                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- TRIAGE COUNT -->

          <div class="container platform-size">
            <div class="card" style="padding:5px; border-radius:15px;">
              <div class="card-body">

                <div>
                  <h5 class="text-center py-2 card-title">Triage Count</h5>
                  <table class="table text-center mx-auto mt-1 mb-4">
                    <thead>
                      <tr class="triage">
                        <td class="col-2" :title="triage_output(1)">{{ triage_output(1) }} (1)</td>
                        <td class="col-2" :title="triage_output(2)">{{ triage_output(2) }} (2)</td>
                        <td class="col-2" :title="triage_output(3)">{{ triage_output(3) }} (3)</td>
                        <td class="col-2" :title="triage_output(4)">{{ triage_output(4) }} (4)</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="col-2" v-for="(triage_val, key) in triage_count">{{triage_val}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>

          <!-- LOOPING TABLE CASES -->

          <div class="container platform-size">
            <div class="card m-auto" style="padding:5px; border-radius:15px;">
              <div class="card-body">

                <h5 class="text-center py-2 card-title pb-3">Case List</h5>

                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Search..." v-model="search_case" @keyup.enter="get_search_case">
                  <div class="input-group-append">
                    <button class="btn btn-outline-success" type="button" @click="get_search_case">Search</button>
                  </div>
                </div>

                <div class="table-responsive d-flex" v-if="filtered_cases.length > 0 ">
                  <table class="table table-borderless no-wrap text-center overflow-scroll qmed-color-table">
                    <thead>
                      <tr>
                        <td>NO</td>
                        <td class="col-2">Queue</td>
                        <!-- <td style="width:340px;">UUID</td> -->
                        <td>Age</td>
                        <td>Sex</td>
                        <td>Main Complaints</td>
                        <td>Triage Result</td>
                        <td>Date</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cases_data, key, index) in paginate_list(filtered_cases, 10)[paginate_case].data" v-bind:key="index" v-if="!case_search_error" >
                        <td>{{parseInt(key) + 1}}</td>
                          <div class="queue-border my-1 px-1" v-if="cases_data.QueueNumber"><Icon icon="fluent:people-queue-20-regular" class="mr-1"/>{{cases_data.QueueNumber}}</div>
                          <div class="queue-border none my-1 px-1" v-else><Icon icon="fluent:people-queue-20-regular" class="mr-1"/> None</div>
                        <!-- <td>{{cases_data.uuid}}</td> -->
                        <td>{{cases_data.age}}</td>
                        <td>{{cases_data.gender}}</td>
                        <td>{{cases_data.main_complaint}}</td>
                        <td>
                          <div class="table-triage">
                            <div :class="getTriageClass(cases_data.triage)">
                              {{ triage_output(cases_data.triage) }} ({{ cases_data.triage }})
                            </div>
                          </div>


                        </td>
                        <td>{{ dateToStr(cases_data.date)  }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="text-center" v-else>
                  No Available Data
                </div>

                    <div class="text-center text-red" v-if="case_search_error">Did not found anything similar</div>
              </div>

              <!-- NAV PAGINATE FOR COMPLAINT -->

              <nav aria-label="Page navigation example" style="padding:6px; margin-bottom: 10px;" v-if="!case_search_error">
                <ul class="pagination justify-content-center">
                  <li class="page-item">
                    <a v-if="paginate_case>0" class="page-link" aria-label="Previous" @click="paginate_case = paginate_case - 1">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                    <a v-else class="page-link disabled" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>

                  </li>
                  <li class="page-item" v-for="(data, key) in paginate_list(filtered_cases, 10)">
                    <a v-if="paginate_case == key" class="page-link active" @click="paginate_case = key">{{parseInt(key) + 1}}</a>
                    <a v-else class="page-link" @click="paginate_case = parseInt(key)">{{parseInt(key) + 1}}</a>

                  </li>

                  <li class="page-item">
                    <a v-if="paginate_case< (count_obj(paginate_list(filtered_cases, 10))-1)" class="page-link" aria-label="Next" @click="paginate_case = paginate_case + 1">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                    <a v-else class="page-link disabled" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>

          <!-- GRAPH CASES -->

          <div class="container platform-size" >
            <div class="card" style="padding:5px; border-radius:15px;">
              <div class="card-body justify-content-center" v-if="graph_data.length > 0">

                <h5 class="text-center card-title center" >
                  Cases Graph for
                  <a v-if="period=='1month'">1 Month</a>
                  <a v-else-if="period=='7days'">1 Week</a>
                </h5>
                <div class="text-center my-3">
                  <button v-if="cases_graph == 1" class="btn btn-success" @click="cases_graph = 2"><a>Total Cases</a></button>
                  <button v-if="cases_graph == 2" class="btn btn-success" @click="cases_graph = 1"><a>Details</a></button>
                </div>

                <BarChartDetail v-if="cases_graph == 2" class="mb-3" :data_input="graph_data"/>
                <BarChartTotal  v-if="cases_graph == 1" class="mb-3" :data_input="graph_data"/>

              </div>

              <div class="card-body justify-content-center" v-else>
                <h5 class="text-center card-title center" >
                  Cases Graph for
                  <a v-if="period=='1month'">1 Month</a>
                  <a v-else-if="period=='7days'">1 Week</a>
                </h5>
                <div class="text-center my-3">
                  <button class="btn btn-success"><a>No Available Data</a></button>
                </div>
              </div>

            </div>
          </div>

          <!-- LOOPING TABLE COMPLAINT -->

          <div class="container platform-size">
            <div class="card" style="padding:5px; border-radius:15px;">
              <div class="card-body">

                <!-- <h6 class="card-subtitle mb-2">Average count per day : {{ avg_count_per_day }}</h6> -->
                <h5 class="text-center py-2 card-title">Symptoms Count</h5>

                <table class="table table-borderless mt-1" v-if="count_obj(main_complaints, 10)>0">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Complaints</th>
                      <th @click="sort_count_complaint" style="cursor: pointer;">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(complaints_count, complaints_list, index) in paginate_list(main_complaints, 10)[paginate_complaint].data">
                      <td class="col-sm">{{ (paginate_complaint*10) + index + 1 }}</td>
                      <td class="col-10">{{complaints_list}}</td>
                      <td class="col-10">{{complaints_count}}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="text-center">
                  <a>No Available Data</a>
                </div>
              </div>

              <!-- NAV PAGINATE FOR COMPLAINT -->

              <nav aria-label="Page navigation example" style="padding:6px; margin-bottom: 10px;">
                <ul class="pagination justify-content-center">
                  <li class="page-item">
                    <a v-if="paginate_complaint>0" class="page-link" aria-label="Previous" @click="paginate_complaint = paginate_complaint - 1">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                    <a v-else class="page-link disabled" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>

                  </li>
                  <li class="page-item" v-for="(data, key) in paginate_list(main_complaints, 10)">
                    <a v-if="paginate_complaint == key" class="page-link active" @click="paginate_complaint = key">{{parseInt(key) + 1}}</a>
                    <a v-else class="page-link" @click="paginate_complaint = parseInt(key)">{{parseInt(key) + 1}}</a>

                  </li>

                  <li class="page-item">
                    <a v-if="paginate_complaint< (count_obj(paginate_list(main_complaints, 10))-1)" class="page-link" aria-label="Next" @click="paginate_complaint = paginate_complaint + 1">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                    <a v-else class="page-link disabled" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>

  </template>

<script>
import Icon from "@/components/icons/Icon.vue";
import BarChartDetail from "./components/BarChartDetail.vue";
import BarChartTotal from "./components/BarChartTotal.vue";
import { fetchDashboardData} from '@/services/dashboardService';

export default{
  components: {
    Icon,
    BarChartDetail,
    BarChartTotal,
  },

  created(){
  },
  mounted() {
    this.load_page();
  },
  unmounted() {
    this.clear_all_interval();
  },
  data() {
    return {
      user: {},
      dashboard_data : [],
      total_count : 0,
      gender_count : [],
      age_group_count : [],
      main_complaints : [],
      complaints_index : 0,
      triage_count : [],
      paginate_complaint : 0,
      paginate_case : 0,
      search_case : '',
      avg_count_per_day : [],
      period: '1month',
      period_switch: false,
      sort: 0,
      start_date: '',
      end_date: '',
      cases: {},
      filtered_cases : {}, //filtered ver of the cases data
      uuid_details : [],
      uuid_input : '',
      uuid_str_error : '',
      case_search_error : 0,
      cases_graph : 1,
      graph_data : [],

      dashboardInterval: null,

    }
  },
  computed: {

  },
  methods: {

    async load_page(){
      this.clear_all_interval();
      window.scrollTo(0,0);

        this.get_dashboard_data(this.period); //first innitiate
        this.dashboardInterval = setInterval(() => {
            this.get_dashboard_data(this.period);
        }, 10000);


    },

    clear_all_interval(){
      clearInterval(this.dashboardInterval);
    },

    async get_dashboard_data(p){
      //console.log("loading dashboard data.. " + p);

      window.scrollTo(0, window.scrollY);
      //let location = 'kiosk%2CWTC%20Mediclinic';
      let location = this.param_location;

          const data = await fetchDashboardData(location, p); //p is period

          if(data){
            this.dashboard_data = data;
            this.total_count        = this.dashboard_data.total_count;
            this.gender_count       = this.remove_unknown(this.dashboard_data.gender_count);
            this.age_group_count    = this.remove_unknown(this.dashboard_data.age_group_count);
            this.main_complaints    = this.dashboard_data.main_complaints;
            this.avg_count_per_day  = parseInt(this.dashboard_data.average_count_per_day);
            this.triage_count = this.dashboard_data.triage_count;
            this.cases = this.dashboard_data.latest_cases.reverse();
            this.filtered_cases = this.cases;
            this.graph_data = this.dashboard_data.graph_data;

            this.main_complaints = this.desc_obj(this.main_complaints); //descending object of main complaints

            this.process_date_string();
          }
    },

    dashboard_route(){
      this.$router.push('/');
    },

    get_search_case() {
        // Safeguard in case search_case itself is null
        const searchTerm = (this.search_case ?? '').toLowerCase();

        console.log(searchTerm);
        console.log(this.cases);

        // Filter cases based on matching fields
        const temp_filter = this.cases.filter(caseItem => {
            // Use optional chaining (?.) on each field
            const ageString      = caseItem.age?.toString() ?? '';
            const dateString     = new Date(caseItem.date).toISOString().toLowerCase();
            const genderString   = caseItem.gender?.toLowerCase() ?? '';
            const complaintString= caseItem.main_complaint?.toLowerCase() ?? '';
            const queueString    = caseItem.QueueNumber?.toLowerCase() ?? '';
            
            return (
            ageString.includes(searchTerm) ||
            dateString.includes(searchTerm) ||
            genderString === searchTerm ||
            complaintString === searchTerm ||
            queueString === searchTerm
            );
        });

        if (temp_filter.length !== 0) {
            this.case_search_error = 0;
            console.warn("Found Similar!");
            this.filtered_cases = temp_filter;
        } else {
            console.warn("Did not Found anything!");
            this.case_search_error = 1;
        }
    },

    process_date_string(){
      let start_date = new Date(this.dashboard_data.start_date);
      let end_date = new Date(this.dashboard_data.end_date);

      let string_start = 'd/m/Y'
        .replace('Y', start_date.getFullYear())
        .replace('m', start_date.getMonth()+1)
        .replace('d', start_date.getDate());

      let string_end = 'd/m/Y'
      .replace('Y', end_date.getFullYear())
      .replace('m', end_date.getMonth()+1)
      .replace('d', end_date.getDate());

      this.start_date = string_start;
      this.end_date = string_end;
    },

    change_period(){
      let per = this.period;

      switch(per){
        case '1month':
          this.period = '7days';
        break;

        case '7days':
          this.period = '1month';
        break;
      }

      this.get_dashboard_data(this.period);
      this.paginate_complaint = 0;
      this.paginate_case = 0;
    },

    remove_unknown(data_obj){
      //console.log(data_obj);
      let new_data = {};

      for (const [key, value] of Object.entries(data_obj)) {
          if(key.toLowerCase() != 'unknown'){
            new_data[key] = value;
          }
          else{
            //console.warn("Detected got 'Unknown' key on " + data_obj.constructor.name);
          }
      }

      return new_data;
    },

    sort_count_complaint(){

      let sorted_count;

      switch(this.sort){
        case 0:
          sorted_count = this.assc_obj(this.main_complaints);
          this.sort = 1;
        break;
        case 1:
          sorted_count = this.desc_obj(this.main_complaints);
          this.sort = 0;
        break;
      }

      //console.log(sorted_count);
      this.paginate_complaint = 0;
      this.main_complaints = sorted_count;
      //console.log(this.count_obj(this.paginate_list(this.main_complaints, 10)));
    },

    //descending object
    desc_obj(dict){
      let sortedDict = Object.fromEntries(Object.entries(dict).sort(([,a],[,b]) => b- a));
      return sortedDict;
    },

    //asscending object
    assc_obj(dict){
      let sortedDict = Object.fromEntries(Object.entries(dict).sort(([,a],[,b]) => a- b));
      return sortedDict;
    },

    paginate_list(data, input_limit){
      let count = 1;
      let index = 0;
      let limit = input_limit; //limit data per pages
      let new_data = {};
      let temp_arr = {};

      for (const [key, value] of Object.entries(data)) {
        if(count<limit){
          temp_arr[key] = value;
          new_data[index] = {
            data : temp_arr,
            page : (index + 1)
          };
          count++;
        }
        else{
          temp_arr[key] = value;
          new_data[index] = {
            data : temp_arr,
            page : (index + 1)
          };
          temp_arr = {}; //reset temp_arr
          index++;
          count = 1;
        }
      }


      return new_data;

    },

    count_obj(data){
      let count = 0;
      for (const [key, value] of Object.entries(data)) {
        count++
      }
      return count;
    },

    dateToStr(data){
      let start_date = new Date(data);

      let string_data = 'H:m d/M/Y'
        .replace('Y', this.addZero(start_date.getFullYear()))
        .replace('M', this.addZero(start_date.getMonth()+1))
        .replace('d', this.addZero(start_date.getDate()))
        .replace('H', this.addZero(start_date.getHours()))
        .replace('m', this.addZero(start_date.getMinutes()));

      return string_data;

    },

    timeToStr(data){
      let start_date = new Date(data);

      let string_data = 'H:m:s'
        .replace('H', this.addZero(start_date.getHours()))
        .replace('m', this.addZero(start_date.getMinutes()))
        .replace('s', this.addZero(start_date.getSeconds()));

      return string_data;

    },

    addZero(i) {
      if (i < 10) {i = "0" + i}
      return i;
    },

    triage_output(triage_num){
      triage_num = parseInt(triage_num);
      switch(triage_num){
        case 1:
          return "Self-care";
          break;
        case 2:
          return "See a Doctor";
          break;
        case 3:
          return "See a Doctor Urgently";
          break;
        case 4:
          return "Emergency Care";
          break;
      }
    },

    getTriageClass(triageValue) {
      switch(triageValue) {
        case '1':
          return 'first';
        case '2':
          return 'second';
        case '3':
          return 'third';
        case '4':
          return 'fourth';
        default:
          return ''; // Default class or empty if no match
      }
    },

    //QUEUE LIST METHODS
    get_today_date(){
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    goToCases(uuid){
        this.$router.push({ name: 'cases-detail', params: { uuid } });
    }


  }
}

</script>

<style scoped>

.container{
  font-family: 'Figtree', sans-serif;
}

.modal-container{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

table{
  border-radius : 5px;
}

.platform-size{
  width:80% !important;
  padding:10px !important;
}

.platform-size-nav{
  width:80% !important;
  padding:10px !important;
}

.nav-group{
  padding:0px 10px;
  background-color: #f8f9fad9 !important;
}

.logo-img{
  width:17%
}

.navbar-brand{
  font-size: 26px !important;
  font-weight: 600;
}

.row .card .card-body{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-body .card-title{
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
}

.age-title{
  opacity: 0.8;
  font-weight: 600 !important;
  font-size: 20px;
}

.gender-title{
  opacity: 0.8;
  font-weight: 600 !important;
  font-size: 19px;
}

.card {
    box-shadow: none;
}

.sec1{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.sec1 .col-6{
  width: 49%;
}

.card-body .age-group{
  flex-direction: row !important;
  padding: 15px 20px;
}

.card-age-title{
  width: 100%;
  padding-left: 0.8rem;
}

.gender-type{
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: black;
  text-decoration: unset;
}

.card-body .card-subtitle{
  margin-top: 0;
  margin-bottom: 0;
  width: 55%;
  text-align: center;
  font-size: 15px;
}

.col-lg-12:first-child .gender-group{
  margin-bottom: 8px;
}

.card-body .card-subtitle a{
  display: block;
  width: 100%;
}

.col-ms:nth-child(1) a{
  background-color:#3ac47dbf;
}

.col-ms:nth-child(2) a{
  background-color:#3f6ad8bf;
}

.col-ms:nth-child(3) a{
  background-color:#f7b924bf;
}


.col-ms:nth-child(4) a{
  background-color:#d92550e6 ;
}

.col-ms:nth-child(1) .card{
  border-bottom: 3px solid #3ac47dbf;
  border-radius: 0.25rem;
}

.col-ms:nth-child(2) .card{
  border-bottom: 3px solid #3f6ad8bf;
  border-radius: 0.25rem;
}

.col-ms:nth-child(3) .card{
  border-bottom: 3px solid #f7b924bf;
  border-radius: 0.25rem;
}

.col-ms:nth-child(4) .card{
  border-bottom: 3px solid #d92550e6;
  border-radius: 0.25rem;
}

.age .card-body{
  padding: 14px 10px 14px 10px;
}

.table thead{
  font-weight: 600;
    text-transform: uppercase;
    border-bottom: 2px solid #dbdbdb;
}

.table thead td{
  opacity: 0.9;
}

.btn-success {
  background-color: #0FBAB2 !important;
  width: auto;
  border: none !important;
  font-weight: 600 !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
}

.btn-success:hover {
    background-color: #17726B !important;
}

.page-link {
    color:black !important;
    opacity: 0.8;
}

.active>.page-link, .page-link.active {
    color: white !important;
    background-color:#0FBAB2 !important ;
    border-color:#0FBAB2 !important;
}

.case-group{
  display: inline-flex;
  align-items: center;
}

.case-title{
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.25rem;
}

.case-search{
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}

.case-search .form-control{
  margin: auto;
  width: 80%;
}

.triage td:first-child{
  color: #4DC99A;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.triage td:nth-child(2){
  color: #F7B924;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.triage td:nth-child(3){
  color: #FF802B;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .first{
  color: #4DC99A;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .second{
  color: #F7B924;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .third{
  color: #FF802B;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .fourth{
  color: #ff2929;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}


.desktop-view{
  display: block;
}

.mobile-view{
  display: none;
}

.calender{
  width:auto;
  background-color: #0FBAB2;
  color:white;
  cursor:pointer;
}

.calender:hover{
  background-color:#17726B;
  color:white
}

@media (max-width: 785px) {

  .desktop-view{
    display: none;
  }

  .mobile-view{
    display: block;
  }


  .platform-size{
    width:95% !important;
    padding:8px !important;
  }

  .platform-size-nav{
    width:100% !important;
    padding:8px !important;
    border-radius: 0px !important;
  }

  .navbar-brand{
    margin-left:auto !important;
    margin-right:auto !important;
  }

  .d-flex{
    margin: auto;

    .btn{
      font-size: 12px !important;
    }
  }

  .nav-group{
    padding:0px;
  }

  .logo-img{
    width: 50%;
    margin: auto;
    padding: 8px 0 15px 0;
  }
}
@media (max-width: 1080px) {
  .age .card-body {
    min-height: 80px;
  }
}

.nav-btn {
  border-radius: 10px;
  padding: 10px 16px;
  background-color: #0FBAB2;
  color: white;
  cursor: pointer;
  font-size: 15px;
}

.nav-btn.red{
  background-color: #d92550e6 !important;
}

.nav-btn.red:hover{
  background-color: #a11838e6 !important;
}


/* Style the active class */
.nav-btn.active {
  background-color: #17726B;
  color: white;
}

/* Style the buttons on mouse-over */
.nav-btn:hover {
  background-color: #17726B;
  color: white;
}

.queue-border{
  background-color: #3ac47dbf;
  border-radius: 9px;
  color: white;
  width: auto;
  padding: 2px;
}

.none{
  background-color: #d92550e6;
}


.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
}

.right-item {
  position: absolute;
  top: 10px;
  right: 0px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.absolute-left {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 2rem;
}

.left-item {
  position: absolute;
  top: 10px;
  left: 0px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.card-disclaimer {
  width: fit-content;
  padding: 6px;
  background-color: #fff3cd;
  border-radius: 4px;
  font-size: 12px;
}

.card-disclaimer p {
  margin: 0;
  font-size: 0.9em;
  color: #856404;
}

.button-icon{
  color: black;
  font-size: 32px;
  margin: 8px;
  line-height: 0;
  svg{
    margin-top: 8px;
  }
}

.button-icon:hover{
  color:#17726B;
  cursor: pointer;
}

.slidevertical-move,
.slidevertical-enter-active,
.slidevertical-leave-active{
  transition: all 0.5s ease;
}
.slidevertical-enter-from{
  opacity: 0;
  transform: translateY(-1000px);
}
.slidevertical-leave-to{
  opacity: 0;
  transform: translateY(1000px);
}
.slidevertical-leave-active{
  position:absolute;
}

.form-check-input{
  height: 23px !important;
  width: 45px !important;
  border-color:#0FBAB2 !important;
  cursor: pointer;
}
.form-check-input:checked{
  background-color:#0FBAB2 !important;
}


</style>
