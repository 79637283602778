<template>
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="process_data"
    />
  </template>

  <script>
  import { defineComponent, defineProps } from 'vue';
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  export default defineComponent({
    components: { Bar },

    props: ['data_input'],

    data() {
      return {
        chartOptions: {
          responsive: true,
        }
      }
    },

    computed: {
        process_data(){
            //console.log(this.data_input);

            let data_label = [];
            let total = [];

            for(let i=0; i<this.data_input.length; i++){
                data_label.push(this.data_input[i].date);
                total.push(this.data_input[i].total_cases);
            }

            let output_data = {
                labels: data_label,
                datasets : [
                    {
                        label: 'Total Cases',
                        backgroundColor: '#0FBAB2 ',
                        data: total
                    }
                ]
            }

            return output_data

        }
    },





  });
  </script>
