import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

export const getAllClinics = async (retryAttempt = false) => {
    const authStore = useAuthStore();
    try {
        const response = await axios.get(`${await authStore.get_url()}/clinics`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllClinics(true); // Retry after refreshing
        }
        throw error;
    }
}

export const getAllClinicsArr = async (retryAttempt = false) => {
  const authStore = useAuthStore();

  try {
      const response = await axios.get(`${await authStore.get_url()}/clinics`, {
      headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
      },
      });

        let res_data = response.data.data;
        let result = [];

        if(res_data){
            for(let i=0; i<res_data.length; i++){
                
                let temp = {
                    name: res_data[i].ClinicName,
                    uuid: res_data[i].LocationUuid,
                }

                result.push(temp)
            }
        }
  
      return result;
      
  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getAllClinicsArr(true); // Retry after refreshing
      }
      throw error;
  }
}

export const registerClinics= async (input_data, retryAttempt = false) => {
    const authStore = useAuthStore();
    
    try {
      const response = await axios.post(`${await authStore.get_url()}/clinics`, input_data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerClinics(true); // Retry after refreshing
        }
        throw error;
    }
  };

export const editClinics = async (input_data, id, retryAttempt = false) => {
  const authStore = useAuthStore();
  try {
    const response = await axios.put(`${await authStore.get_url()}/clinics/${ id }`, input_data, {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });

    return response.data; // Return response data upon successful registration
  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return editClinics(input_data, id, true); // Retry after refreshing
      }
      throw error;
  }
};

export const getCurrentClinic = async (location_uuid, retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.get(`${await authStore.get_url()}/clinics/${location_uuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentClinic(location_uuid, true); // Retry after refreshing
        }
        throw error;
    }
};